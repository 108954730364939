<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-x-xs gap-y-xs lg:gap-x-sm">
    <GridItem col-span="1" :breakpoints="{}" v-for="index in 6" :key="index" v-if="isLoading">
      <ProductCard
        link-path="/"
        :is-placeholder="true"
        @card-branch-select="setBranchTray"
        :content="{
          title: '',
          image: '',
          desktopImage: '',
          quantity: 1,
          total: 2,
          vat: true,
          hasVat: true,
          unit: '',
          personalisedText: '',
          priceEach: 10,
          offerPriceEach: 'each',
          alert: '',
          collection: true,
          subtitle: '',
        }"
        :branch-selected="!!selectedBranch"
        :signed-in="false"
        :usp="{ availability: 'collect', dropshipLink: '/', branch: '', branchNumber: '' }"
        product-i-d="2"
        :is-tally="false"
        :is-enquire="false"
        :greener-option="{ variant: 'none' }"
        :image-loading="index < 3 ? 'eager' : 'lazy'"
        data-test-id="plpProductPods"
        data-fs="plpProductPods"
      />
    </GridItem>

    <GridItem
      data-test-id="plpProductPods"
      v-if="!isLoading && products.length > 0"
      v-for="(item, index) in products"
      :key="index"
      col-span="1"
      :breakpoints="{}"
    >
      <ProductCard
        v-if="item"
        @add-item-to-basket="handleAddItemToBasket"
        @card-clicked="fireSelectItemEvent(item)"
        @card-is-tally-product="tallyHandler(item)"
        @card-branch-select="setBranchTray"
        @card-sign-in="setLoginTray"
        @dropship="setDropshipTray"
        @open:branch-tray="setBranchTray"
        :link-path="item.link"
        :is-placeholder="false"
        :content="item.product"
        :branch-selected="!!selectedBranch"
        :selected-branch="selectedBranch"
        :signed-in="!!user"
        :user-name="user?.profile?.first_name || ''"
        :usp="{
          availability: item.availability,
          dropshipLink: '/',
          branch: selectedBranch?.name || '',
          branchNumber: selectedBranch?.contacts?.phone || '',
          availabilityReason: item.availability_reason,
        }"
        :product-i-d="item.id"
        :is-tally="item.isTally"
        :is-enquire="false"
        :greener-option="{ variant: item.greenerOption }"
        :image-loading="index === 0 ? 'eager' : 'lazy'"
        :link-component="NuxtLink"
        data-test-id="productPods"
      />
    </GridItem>

    <GridItem col-span="2" :breakpoints="{ lg: '4' }" v-if="!isLoading && categoryBCData.items.length === 0">
      <CategoryNotFound />
    </GridItem>
  </div>

  <Pagination
    :key="page"
    class="my-lg"
    :items="pagesLength"
    :page="page"
    :buttons="3"
    :per-page="10"
    v-if="!isSearch && totalFoundItems > 0"
    @update:page="updatePage"
    data-test-id="plpPagination"
    data-fs="plpPagination"
  />

  <Pagination
    :key="paginationKey"
    class="my-lg"
    :items="pagesLength"
    :page="currentSearchPage"
    :buttons="3"
    :per-page="10"
    v-if="isSearch && totalFoundItems > 0"
    @update:page="updatePage"
  />

  <LazyTallyTray
    v-if="tallyProduct"
    :product-info="tallyProduct"
    :product-default-pricing="tallyProduct"
    :price="tallyProductPrice"
    :selected-branch-id="selectedBranchId"
  />
</template>

<script lang="ts" setup>
import type { CategoryGridProps } from "./categoryTypes";
import { useGA4EcommerceEvents, ViewItemListEvent } from "mkm-gtm";
import { ROUTES } from "~/helpers/routes/routes";
import { IMAGES } from "@/constants/images";
import { productUnitTranslation } from "~/utils/productUnitTranslation";

type Availability = "collect" | "delivery" | "quote" | "outOfStock" | "dropship" | "collection";

const props = defineProps<CategoryGridProps>();
const NuxtLink = resolveComponent("NuxtLink");
const route = useRoute();
const routeSlug = ref(route.query.q === undefined ? "" : decodeURIComponent(route.query.q as string));

const { setBranchTray, isVatIncluded, setBasketTray, setTallyTray, setLoginTray, setDropshipTray } = useUIState();
const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();
const { currentPage, currentSearchPage } = useCategoryPage();
const { filters, filtersChange, hasFilters } = useFilters();
const { selectedBranch, selectedBranchId } = useBranches();
const { generateBreadcrumbs } = useCategoryPage();
const { data: categoryBCData } = useCategory();
const item_categories = generateBreadcrumbs();
const { addCartItemQuantity } = useCart();
const { user, userCategory } = useUser();

const page = ref(currentPage.value ?? 1);
const searchPage = ref(currentSearchPage.value ?? 1);
const paginationKey = ref(0);
let initialBranchLoad = true;

const tallyProduct = ref<any>();
const tallyProductPrice = ref<any>();
const categories = ref<{ count: number; id: string; name: string; parentId: string | null }[]>([]);
const item_list_id = item_categories[item_categories.length - 1].title;

const pagesLength = computed(() => Math.ceil(props?.totalFoundItems / props.itemsPerPage) || 1);

const fetchPage = async (value: number) => {
  props.isSearch ? await props.fetchNewPageOnSearchPage?.(value) : await props.fetchNewPage?.(value);
};

const calculatePageType = (): ViewItemListEvent["page_type"] => {
  const { SEARCH, CATEGORY } = ROUTES;
  return route.path.startsWith(SEARCH) ? "search" : route.path.startsWith(CATEGORY) ? "category" : "other";
};

const getProductLink = (item: any) => {
  const link = item?.customAttrs
    ?.filter((attribute: Record<string, string>) => attribute.name === "url")[0]
    ?.values[0]?.slice();

  return link.slice(Math.max(0, link.indexOf("/product")));
};

const handleAddItemToBasket = async ({ quantity, productID }: { quantity: number; productID: string }) => {
  await addCartItemQuantity(productID, quantity);
  setBasketTray(true);
};

const getCustomAttrValue = (product: any, attrName: any) => {
  if (product.customAttrs?.length > 0) {
    const attr = product.customAttrs.find((attr: any) => attr?.name === attrName);
    return attr ? attr?.values[0] : null;
  }
};

const findProductPrice = (product: any, prices: any) => {
  const productID = product.id || product.itemId.id;
  if (productID && prices && prices.length > 0) {
    return prices.find((it: any) => it.product_sku === productID);
  }
  return null;
};

const getStockType = (flag: number) => (flag === 1 ? "BRANCH STOCKED" : flag === 4 ? "DROPSHIP" : null);

// eslint-disable-next-line sonarjs/cognitive-complexity
const fireSelectItemEvent = async (item: any) => {
  const pageType = calculatePageType();
  const itemListName = pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null;

  await pushGA4EcommerceEvent(GA4EcommerceEvents.SELECT_ITEM, {
    logged_in: user.value !== null || false,
    user_id: user.value?.id ?? null,
    customer_category_1: userCategory?.[0] === "NTrade" ? "NON-TRADE" : "TRADE",
    customer_category_2: userCategory?.[1] ?? null,
    customer_category_3: userCategory?.[2] ?? null,
    account_owning_branch: Number(user.value?.account?.branch_id) ?? null,
    account_type: user.value?.account.type ?? null,
    category: categories.value[0]?.name ?? null,
    selected_branch: selectedBranch.value?.name ?? null,
    price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
    price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
    stock_type: getStockType(Number(getCustomAttrValue(item, "availability_flag"))) ?? null,
    ecommerce: {
      currency: "GBP",
      value: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
      item_list_name: itemListName,
      item_list_id: itemListName,
      items: [
        {
          item_list_id: itemListName,
          item_list_name: itemListName,
          item_id: item?.itemId?.id || item?.id,
          item_name: item?.displayName ?? null,
          bigcommerce_item_id: getCustomAttrValue(item, "bcid"),
          price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
          price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
          quantity: 1,
        },
      ],
    },
  });
};

const tallyHandler = (item: any) => {
  tallyProduct.value = item;
  tallyProductPrice.value = findProductPrice(item, props.prices);

  setTimeout(() => {
    setTallyTray();
  }, 100);
};

const updatePage = (value: any) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  props.isSearch ? (searchPage.value = value) : (page.value = value);
};

const getProductBadges = (customAttrs: Record<string, string>[]) => {
  const badges = new Set(["cf_icon-green", "cf_icon-materials", "cf_icon-energy", "cf_icon-planet"]);

  const badgeIcons = customAttrs
    .filter(({ name, values }) => badges.has(name) && values[0].includes("Y"))
    .map(({ name }) => name.replace("cf_icon-", ""));

  if (badgeIcons.length > 1) return "green";
  else if (badgeIcons.length === 0) return "none";
  else return badgeIcons[0] as "green" | "none" | "energy" | "materials" | "planet";
};

const getProductAvailability = (customAttrs: Record<string, string>[]) => {
  const availability = customAttrs.find(({ name }) => name === "availability_flag");

  const availabilityClass: { [key: number]: Availability } = {
    0: "collect",
    1: "delivery",
    2: "quote",
    3: "outOfStock",
    4: "dropship",
  };

  return availability ? availabilityClass[Number(availability.values[0])] : "collection";
};

const getProductDetails = (item: any) => {
  const productPrice = findProductPrice(item, props.prices);
  return {
    image: item.imageSet.thumbnail.link.href ?? IMAGES.PLACEHOLDER,
    desktopImage: item.imageSet.original.link.href ?? IMAGES.PLACEHOLDER,
    title: item.displayName,
    subtitle: "",
    quantity: 1,
    total: 2,
    vat: isVatIncluded.value,
    hasVat: isVatIncluded.value,
    unit: productUnitTranslation(productPrice?.unit),
    personalisedText: "",
    priceEach: isVatIncluded.value
      ? Number(productPrice?.price_inc_vat) || Number(getCustomAttrValue(item, "price_inc_vat"))
      : Number(productPrice?.price) || Number(getCustomAttrValue(item, "price")),
    offerPrice: isVatIncluded.value
      ? Number(productPrice?.offer_price_inc_vat) || Number(getCustomAttrValue(item, "offer_price_inc_vat"))
      : Number(productPrice?.offer_price) || Number(getCustomAttrValue(item, "offer_price")),
    offerPriceEach:
      productUnitTranslation(productPrice?.offer_unit) ||
      productUnitTranslation(getCustomAttrValue(item, "offer_unit")),
    alert: "",
    collection: true,
  };
};

const products = computed(() => {
  return categoryBCData.value.items.map((item: any) => {
    return {
      id: item.itemId.id,
      availability: getProductAvailability(item.customAttrs),
      availability_reason: getCustomAttrValue(item, "availability_reason"),
      greenerOption: getProductBadges(item.customAttrs),
      link: getProductLink(item),
      product: getProductDetails(item),
      isTally: getCustomAttrValue(item, "is_tally") !== "0",
    };
  });
});

watch(
  () => route.query.p,
  async (newPage) => {
    updatePage(Number(newPage || 1));
  },
);

watch(page, async (value) => {
  await nextTick();
  await props.fetchNewPage?.(value);
});

watch(searchPage, async (value) => {
  await nextTick();
  paginationKey.value++;
  await props.fetchNewPageOnSearchPage?.(value);
});

watch(
  filters,
  async (updatedData, initialData) => {
    if (filtersChange.value) {
      if (updatedData === initialData) return;

      filtersChange.value = false;
      hasFilters.value = true;

      await fetchPage(1);
    }
  },
  { deep: true },
);

watch(selectedBranch, async () => {
  if (initialBranchLoad) {
    initialBranchLoad = false;
    page.value = Number(route.query.p) || 1;
    await fetchPage(page.value);
    return;
  }

  page.value = 1;
  await fetchPage(page.value);
});

// eslint-disable-next-line sonarjs/cognitive-complexity
onMounted(() => {
  if (categoryBCData.value.items?.length === 0) fetchPage(1);

  categories.value = categoryBCData.value.facetResult.fields.find(
    (item: { [key: string]: string }) => item?.id === "category",
  )?.values;

  const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;

  setTimeout(async () => {
    const pageType = calculatePageType();
    const itemListName = pageType === "search" ? `Search results for ${routeSlug.value}` : item_list_id ?? null;
    await pushGA4EcommerceEvent(GA4EcommerceEvents.VIEW_ITEM_LIST, {
      logged_in: user.value !== null || false,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_owning_branch: Number(user.value?.account?.branch_id) ?? null,
      account_type: user.value?.account.type ?? null,
      category: categories.value?.[0]?.name ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      page_type: pageType ?? null,
      search_term: routeSlug.value !== "" || null ? routeSlug.value : null,
      search_results: pageType === "category" ? null : props.totalFoundItems,
      ecommerce: {
        currency: "GBP",
        item_list_name: itemListName,
        items: categoryBCData.value.items.map((item: any, index: number) => {
          return {
            item_list_id: itemListName,
            item_list_name: itemListName,
            item_id: item?.itemId.id ?? null,
            item_name: item.displayName ?? null,
            bigcommerce_item_id: getCustomAttrValue(item, "bcid"),
            index: index,
            price: Number.parseFloat(getCustomAttrValue(item, "price_inc_vat")) ?? null,
            price_ex_vat: Number.parseFloat(getCustomAttrValue(item, "price")) ?? null,
            quantity: 1,
            stock_type: getStockType(Number(getCustomAttrValue(item, "availability_flag"))) ?? null,
          };
        }),
      },
    });
  }, 1200);
});
</script>
